import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { InteractiveList, PartialInteractiveList } from '../index';
import { items, navigationItems } from './helpers/items';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="compositions/InteractiveList" title="InteractiveList" mdxType="PageTitle" />
    <p>{`Keyboard navigation is available for this component. Navigate with `}<inlineCode parentName="p">{`Up`}</inlineCode>{`, `}<inlineCode parentName="p">{`Down`}</inlineCode>{`, `}<inlineCode parentName="p">{`Left`}</inlineCode>{`, `}<inlineCode parentName="p">{`Right`}</inlineCode>{`, `}<inlineCode parentName="p">{`Home`}</inlineCode>{` or `}<inlineCode parentName="p">{`End`}</inlineCode>{`. Toggle with `}<inlineCode parentName="p">{`Space`}</inlineCode>{`. Select with `}<inlineCode parentName="p">{`Enter`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "scroll-to-element"
    }}>{`Scroll to element`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <InteractiveList\n    scrollable\n    initialSelected={[\'violet-red\']}\n    items={items}\n    onItemFocus={(event, { id, index }) =>\n      console.log(\'focus\', { id, index })\n    }\n    onSelect={(event, { id, index }) => console.log(\'select\', { id, index })}\n    onUnselect={(event, { id }) => console.log(\'unselect\', id)}\n    style={{ height: \'200px\' }}\n    variant=\"bordered\"\n  >\n    An empty notification\n  </InteractiveList>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      InteractiveList,
      PartialInteractiveList,
      items,
      navigationItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <InteractiveList scrollable initialSelected={['violet-red']} items={items} onItemFocus={(event, {
          id,
          index
        }) => console.log('focus', {
          id,
          index
        })} onSelect={(event, {
          id,
          index
        }) => console.log('select', {
          id,
          index
        })} onUnselect={(event, {
          id
        }) => console.log('unselect', id)} style={{
          height: '200px'
        }} variant="bordered" mdxType="InteractiveList">
      An empty notification
    </InteractiveList>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "select-on-focus"
    }}>{`Select on focus`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <InteractiveList\n    scrollable\n    selectOnFocus\n    initialSelected={[\'red\']}\n    items={items}\n    onItemFocus={(event, { id, index }) =>\n      console.log(\'focus\', { id, index })\n    }\n    onSelect={(event, { id, index }) => console.log(\'select\', { id, index })}\n    onUnselect={(event, { id }) => console.log(\'unselect\', id)}\n    style={{ height: \'200px\' }}\n    variant=\"bordered\"\n  >\n    An empty notification\n  </InteractiveList>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      InteractiveList,
      PartialInteractiveList,
      items,
      navigationItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <InteractiveList scrollable selectOnFocus initialSelected={['red']} items={items} onItemFocus={(event, {
          id,
          index
        }) => console.log('focus', {
          id,
          index
        })} onSelect={(event, {
          id,
          index
        }) => console.log('select', {
          id,
          index
        })} onUnselect={(event, {
          id
        }) => console.log('unselect', id)} style={{
          height: '200px'
        }} variant="bordered" mdxType="InteractiveList">
      An empty notification
    </InteractiveList>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "mimic-select-on-focus"
    }}>{`Mimic select on focus`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <InteractiveList\n    mimicSelectOnFocus\n    scrollable\n    initialSelected={[\'red\']}\n    items={items}\n    onItemFocus={(event, { id, index }) =>\n      console.log(\'focus\', { id, index })\n    }\n    onSelect={(event, { id, index }) => console.log(\'select\', { id, index })}\n    onUnselect={(event, { id }) => console.log(\'unselect\', id)}\n    style={{ height: \'200px\' }}\n    variant=\"bordered\"\n  >\n    An empty notification\n  </InteractiveList>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      InteractiveList,
      PartialInteractiveList,
      items,
      navigationItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <InteractiveList mimicSelectOnFocus scrollable initialSelected={['red']} items={items} onItemFocus={(event, {
          id,
          index
        }) => console.log('focus', {
          id,
          index
        })} onSelect={(event, {
          id,
          index
        }) => console.log('select', {
          id,
          index
        })} onUnselect={(event, {
          id
        }) => console.log('unselect', id)} style={{
          height: '200px'
        }} variant="bordered" mdxType="InteractiveList">
      An empty notification
    </InteractiveList>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "multi-select"
    }}>{`Multi-select`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <InteractiveList\n    scrollable\n    initialSelected={[\'violet-red\']}\n    items={items}\n    maxSelect={-1}\n    onItemFocus={(event, { id, index }) =>\n      console.log(\'focus\', { id, index })\n    }\n    onSelect={(event, { id, index }) => console.log(\'select\', { id, index })}\n    onUnselect={(event, { id }) => console.log(\'unselect\', id)}\n    style={{ height: \'200px\' }}\n    variant=\"bordered\"\n  >\n    An empty notification\n  </InteractiveList>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      InteractiveList,
      PartialInteractiveList,
      items,
      navigationItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <InteractiveList scrollable initialSelected={['violet-red']} items={items} maxSelect={-1} onItemFocus={(event, {
          id,
          index
        }) => console.log('focus', {
          id,
          index
        })} onSelect={(event, {
          id,
          index
        }) => console.log('select', {
          id,
          index
        })} onUnselect={(event, {
          id
        }) => console.log('unselect', id)} style={{
          height: '200px'
        }} variant="bordered" mdxType="InteractiveList">
      An empty notification
    </InteractiveList>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "allow-unselect"
    }}>{`Allow unselect`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <InteractiveList\n    scrollable\n    initialSelected={[\'red\']}\n    items={items}\n    minSelect={0}\n    onItemFocus={(event, { id, index }) =>\n      console.log(\'focus\', { id, index })\n    }\n    onSelect={(event, { id, index }) => console.log(\'select\', { id, index })}\n    onUnselect={(event, { id }) => console.log(\'unselect\', id)}\n    style={{ height: \'200px\' }}\n    variant=\"bordered\"\n  >\n    An empty notification\n  </InteractiveList>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      InteractiveList,
      PartialInteractiveList,
      items,
      navigationItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <InteractiveList scrollable initialSelected={['red']} items={items} minSelect={0} onItemFocus={(event, {
          id,
          index
        }) => console.log('focus', {
          id,
          index
        })} onSelect={(event, {
          id,
          index
        }) => console.log('select', {
          id,
          index
        })} onUnselect={(event, {
          id
        }) => console.log('unselect', id)} style={{
          height: '200px'
        }} variant="bordered" mdxType="InteractiveList">
      An empty notification
    </InteractiveList>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "allow-reselect"
    }}>{`Allow reselect`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <InteractiveList\n    allowReselect\n    scrollable\n    initialSelected={[\'red\']}\n    items={items}\n    minSelect={1}\n    onItemFocus={(event, { id, index }) =>\n      console.log(\'focus\', { id, index })\n    }\n    onSelect={(event, { id, index }) => console.log(\'select\', { id, index })}\n    onUnselect={(event, { id }) => console.log(\'unselect\', id)}\n    style={{ height: \'200px\' }}\n    variant=\"bordered\"\n  >\n    An empty notification\n  </InteractiveList>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      InteractiveList,
      PartialInteractiveList,
      items,
      navigationItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <InteractiveList allowReselect scrollable initialSelected={['red']} items={items} minSelect={1} onItemFocus={(event, {
          id,
          index
        }) => console.log('focus', {
          id,
          index
        })} onSelect={(event, {
          id,
          index
        }) => console.log('select', {
          id,
          index
        })} onUnselect={(event, {
          id
        }) => console.log('unselect', id)} style={{
          height: '200px'
        }} variant="bordered" mdxType="InteractiveList">
      An empty notification
    </InteractiveList>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "custom-list-items"
    }}>{`Custom list items`}</h2>
    <p><em parentName="p">{`Note that the links will cause the page to reload so the state will appear unchanged.`}</em></p>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <InteractiveList\n    scrollable\n    triggerLinks\n    items={[\n      {\n        id: \'first\',\n        label: ({ focused, selected }) => (\n          <a\n            href=\"#linked\"\n            style={{\n              color: \'currentColor\',\n              display: \'block\',\n              height: \'60px\',\n            }}\n          >\n            First {focused ? \'is focused!\' : \'\'}{\' \'}\n            {selected ? \'is selected!\' : \'\'}\n          </a>\n        ),\n      },\n      {\n        id: \'second\',\n        label: ({ focused, selected }) => (\n          <a\n            href=\"#linked\"\n            style={{\n              color: \'currentColor\',\n              display: \'block\',\n              height: \'60px\',\n            }}\n          >\n            Second {focused ? \'is focused!\' : \'\'}{\' \'}\n            {selected ? \'is selected!\' : \'\'}\n          </a>\n        ),\n      },\n      {\n        id: \'third\',\n        label: ({ focused, selected }) => (\n          <a\n            href=\"#linked\"\n            style={{\n              color: \'currentColor\',\n              display: \'block\',\n              height: \'60px\',\n            }}\n          >\n            Third {focused ? \'is focused!\' : \'\'}{\' \'}\n            {selected ? \'is selected!\' : \'\'}\n          </a>\n        ),\n      },\n      {\n        id: \'fourth\',\n        label: ({ focused, selected }) => (\n          <a\n            href=\"#linked\"\n            style={{\n              color: \'currentColor\',\n              display: \'block\',\n              height: \'60px\',\n            }}\n          >\n            Fourth {focused ? \'is focused!\' : \'\'}{\' \'}\n            {selected ? \'is selected!\' : \'\'}\n          </a>\n        ),\n      },\n    ]}\n    style={{ height: \'200px\' }}\n    variant=\"bordered\"\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      InteractiveList,
      PartialInteractiveList,
      items,
      navigationItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <InteractiveList scrollable triggerLinks items={[{
          id: 'first',
          label: ({
            focused,
            selected
          }) => <a href="#linked" style={{
            color: 'currentColor',
            display: 'block',
            height: '60px'
          }}>
              First {focused ? 'is focused!' : ''} {selected ? 'is selected!' : ''}
            </a>
        }, {
          id: 'second',
          label: ({
            focused,
            selected
          }) => <a href="#linked" style={{
            color: 'currentColor',
            display: 'block',
            height: '60px'
          }}>
              Second {focused ? 'is focused!' : ''} {selected ? 'is selected!' : ''}
            </a>
        }, {
          id: 'third',
          label: ({
            focused,
            selected
          }) => <a href="#linked" style={{
            color: 'currentColor',
            display: 'block',
            height: '60px'
          }}>
              Third {focused ? 'is focused!' : ''} {selected ? 'is selected!' : ''}
            </a>
        }, {
          id: 'fourth',
          label: ({
            focused,
            selected
          }) => <a href="#linked" style={{
            color: 'currentColor',
            display: 'block',
            height: '60px'
          }}>
              Fourth {focused ? 'is focused!' : ''} {selected ? 'is selected!' : ''}
            </a>
        }]} style={{
          height: '200px'
        }} variant="bordered" mdxType="InteractiveList" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "navigation-role-with-links-for-right-click-only"
    }}>{`Navigation role with links for right-click only`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <InteractiveList\n    scrollable\n    initialSelected={[\'violet-red\']}\n    items={navigationItems}\n    onItemFocus={(event, { id, index }) =>\n      console.log(\'focus\', { id, index })\n    }\n    onSelect={(event, { id, index }) => console.log(\'select\', { id, index })}\n    onUnselect={(event, { id }) => console.log(\'unselect\', id)}\n    providerProps={{ role: \'navigation\', \'aria-label\': \'Primary navigation\' }}\n    style={{ height: \'200px\' }}\n    variant=\"bordered\"\n  >\n    An empty notification\n  </InteractiveList>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      InteractiveList,
      PartialInteractiveList,
      items,
      navigationItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <InteractiveList scrollable initialSelected={['violet-red']} items={navigationItems} onItemFocus={(event, {
          id,
          index
        }) => console.log('focus', {
          id,
          index
        })} onSelect={(event, {
          id,
          index
        }) => console.log('select', {
          id,
          index
        })} onUnselect={(event, {
          id
        }) => console.log('unselect', id)} providerProps={{
          role: 'navigation',
          'aria-label': 'Primary navigation'
        }} style={{
          height: '200px'
        }} variant="bordered" mdxType="InteractiveList">
      An empty notification
    </InteractiveList>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "empty-list"
    }}>{`Empty list`}</h2>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <InteractiveList items={[]} variant=\"bordered\">\n    An empty notification\n  </InteractiveList>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      InteractiveList,
      PartialInteractiveList,
      items,
      navigationItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <InteractiveList items={[]} variant="bordered" mdxType="InteractiveList">
      An empty notification
    </InteractiveList>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "interactivelist"
    }}>{`InteractiveList`}</h3>
    <Props of={InteractiveList} mdxType="Props" />
    <h3 {...{
      "id": "partialinteractivelist"
    }}>{`PartialInteractiveList`}</h3>
    <Props of={PartialInteractiveList} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      